.profile-package {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 7px;

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 15px;
    align-items: center;

    span {
      display: block;
    }

    :last-child {
      font-size: 18px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 20px 0;

    &-row {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 15px;
      font-weight: 400;

      &--check-icon {
        &::before {
          content: '';
          display: block;
          width: 15px;
          height: 10px;
          background-repeat: no-repeat;
          background-image: url('/static/images/check-inherit.svg');
          background-size: contain;
          background-position: center;
        }
      }

      &--check-icon--active {
        &::before {
          background-image: url('/static/images/check.svg');
        }
      }

      &--delete-icon {
        &::before {
          content: '';
          display: block;
          width: 15px;
          height: 15px;
          background-repeat: no-repeat;
          background-image: url('/static/images/new_search/icons/icon-delete.svg');
        }
      }

      svg {
        display: block;
        align-items: center;
      }

      span {
        display: block;
      }

      &--not-active {
        color: $colour-heathered-grey;
      }
    }
  }

  &__button {
    border-radius: 14px;
    background-color: $colour-white;
    height: 50px;
    color: $colour-neon-blue;
    font-size: 15px;
    font-weight: 400;
  }

  &--bg-color {
    &-basic {
      background: linear-gradient(315deg, #e7e7e7 0%, #f6f7fa 100%);
    }

    &-silver {
      background: linear-gradient(315deg, #d6d6d6 0%, #ededed 100%);
    }

    &-gold {
      background: linear-gradient(320deg, #decfa7 -8.11%, #ede6d2 69.11%);
    }

    &-platinum {
      background: linear-gradient(315deg, #e2e3ea 0%, #f0edff 100%);
    }
  }
}
