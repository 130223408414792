.profile-balance {
  padding: 10px 15px 15px;
  margin-top: 60px;

  &__items {
    display: flex;
    justify-content: space-between;
    gap: 9px;
  }

  &__block {
    width: 100%;
    padding: 15px;
    border-radius: 14px;
    border: 1px solid $colour-grey-wild-sand;
    background: $colour-white;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    height: 20px;
    margin-bottom: 2px;

    &-title {
      width: 100%;

      span {
        color: $colour-heathered-grey;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
      }
    }

    &-dropdown {
      min-width: 20px;
      min-height: 20px;
      position: relative;

      i {
        position: absolute;
        align-items: center;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__count {
    color: $colour-cocoa-brown;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__button {
    height: 40px;
  }
}
