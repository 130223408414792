.profile-info {
  height: 100vh;
  background-color: $colour-background-tertiary;

  &__data {
    padding: 25px 0;
    background-color: $colour-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      display: block;
    }

    i {
      margin-bottom: 13px;
    }

    &-name {
      color: $colour-cocoa-brown;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    &-company {
      color: $colour-heathered-grey;
      font-size: 12px;
    }
  }

  &__list {
    background-color: $colour-white;
    border-top: 1px solid $colour-grey-wild-sand;
    border-bottom: 1px solid $colour-grey-wild-sand;
    padding-top: 10px;
  }

  &__button {
    width: calc(100% - 30px);
    margin: 20px 15px 0;
    border-radius: 14px;
    background-color: $colour-neon-blue;

    &--disabled {
      background-color: #a5c5fc;
    }
  }
}
