.profile-filters {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  position: sticky;
  z-index: 3;
  top: 60px;
  right: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-top: 1px solid $colour-grey-wild-sand;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    min-width: fit-content;
    height: 38px;
    border-radius: 20px;
    font-size: 14px;
    color: $colour-cocoa-brown;
    border: 1px solid $colour-grey-wild-sand;
    background-color: $colour-background-tertiary;

    &--active {
      border: 1px solid $colour-dark-tan;
      background: $colour-dark-tan;
      box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
      color: $colour-background-tertiary;
    }

    &-badge {
      position: absolute;
      top: -5px;
      right: 0;
      height: 15px;
      padding: 1px 5px;
      border-radius: 100px;
      color: $colour-white;
      font-size: 10px;
      font-weight: 500;
      background-color: $colour-heathered-grey;
    }
  }
}
