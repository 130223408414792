.profile {
  font-family: $helvetica;
  background-color: $colour-background-tertiary;
  height: 100vh;

  &__modal-header {
    border-bottom: 0;
    margin-bottom: 10px;
  }

  &__modal-button {
    height: 50px;
    margin-bottom: 20px;
    border-radius: 14px;
    background-color: $colour-neon-blue;
  }
}
