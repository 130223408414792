.profile-buy-items {
  button {
    height: 50px;
    border-radius: 14px;
    margin: 10px 0 5px;
  }

  .promotion__confirmation {
    font-size: 13px;
    color: #a49e97;
  }

  .promotion__header {
    border-bottom: 0;
  }
}
