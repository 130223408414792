.profile-items {
  background-color: $colour-white;

  &__promotion {
    padding: 0 10px 10px;

    &-button {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 40px;
      padding: 0 10px;
      gap: 5px;
      border-radius: 7px;
      background-color: $colour-light-blue;
      color: $colour-neon-blue;
      font-size: 15px;

      i {
        display: block;
        width: 17px;
        height: 100%;
        position: relative;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      span {
        display: block;
        align-self: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
      }
    }
  }
}
