.profile-invoices-list-item {
  padding: 10px 0;
  border-bottom: 1px solid $colour-grey-wild-sand;
  font-size: 15px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &__content {
    display: flex;

    &_info {
      width: 100%;

      &-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        align-items: center;
        color: $colour-cocoa-brown;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          display: block;
        }

        &-price {
          font-weight: 500;
        }

        &--gray {
          color: $colour-heathered-grey;
        }

        &--red {
          color: $colour-red;
        }

        &--green {
          color: $colour-green;
        }

        &--yellow {
          color: #ffb900;
        }
      }
    }

    &_icon {
      position: relative;
      width: 20px;

      svg {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &__download {
    display: block;
    text-align: center;
    padding: 10px 15px;
    height: 40px;
    width: 100px;
    border-radius: 7px;
    text-decoration: none;
    background-color: $colour-light-blue;
    color: $colour-neon-blue;
    margin-top: 10px;
    font-size: 15px;
  }
}
