.profile-info-item {
  &__title {
    color: $colour-heathered-grey;
    font-size: 15px;
    margin-bottom: 2px;
    display: block;
  }

  &__value {
    color: $colour-cocoa-brown;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
  }
}
